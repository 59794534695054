import React from "react";
import "./index.css";

const Send = () => {
  return (
    <div className="send">
      <div className="container-lg">
        <div className="colorBox flex flexRow">
          <div className="textCol flex flexCol justifyCenter">
            <h1 className="relative">
              ¡Gracias!
              <br />
              <span className="stroke">Recibimos tus datos</span>
            </h1>
            <h3 className="relative">
              Un representante de Ford se pondrá en contacto con vos en breve.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Send;
