import "./index.css";
import { useState } from "react";
import arrow from "../../assets/dropdown.svg";

export default function InputForm({
  type,
  name,
  placeholder,
  value,
  pattern,
  handleChangeInput,
  required,
  options,
  error,
}) {
  const [focused, setFocused] = useState(false);
  const handleFocus = (e) => {
    setFocused(true);
  };
  if (type === "text" || type === "email") {
    return (
      <div className="inputDiv">
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          pattern={pattern}
          onChange={handleChangeInput}
          required={required}
          onBlur={handleFocus}
          focused={focused.toString()}
        />
        <span className="errorInput">{error}</span>
      </div>
    );
  }

  if (type === "select") {
    return (
      <div className="inputDiv">
        <select
          type={type}
          name={name}
          onChange={handleChangeInput}
          required={required}
          onBlur={handleFocus}
          focused={focused.toString()}
          defaultValue={"DEFAULT"}
        >
          <option disabled={true} hidden={true} value="DEFAULT">
            {placeholder}
          </option>
          {options.map((option) => (
            <option
              disabled={option.disabled}
              hidden={option.hidden}
              key={option.value}
              value={option.value}
            >
              {option.text}
            </option>
          ))}
        </select>
        <img className="dropdown" src={arrow} alt="Arrow" />

        <span className="errorInput">{error}</span>
      </div>
    );
  }

  if (type === "radio") {
    return (
      <div className="inputDiv">
        <p className="labelForm">{placeholder}</p>
        <div className="radioDiv">
          {options.map((option, key) => (
            <div key={option.value}>
              <input
                id={name + key}
                type="radio"
                name={name}
                value={option.value}
                onChange={handleChangeInput}
                defaultChecked={option.defaultChecked}
              />
              <label htmlFor={name + key}>{option.value}</label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
