import React from "react";
import "./index.css";

import logo from "../assets/logo.jpeg";

const SubFooter = () => {
  return (
    <>
      <div className="section noPadTop subFooter">
        <div className="colImg flex flexRow">
          <div className="imgBck"></div>
          <div className="textFooter">
            <h1>
              Subite a tu <br />
              <strong>Ford 0km</strong>
            </h1>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container-lg flex flexRow flexWrap space">
          <p>
            <img src={logo} alt="logo" />
            Ford Plan de Ahorro -
            <a
              href="https://tengouncero.com.ar/tyc"
              target="_blank"
              style={{ display: "block" }}
              rel="noopener noreferrer"
            >
              Términos y Condiciones
            </a>
          </p>
          <p>
            Mango&amp;Cía
            <a
              href="https://mangoycia.com/?utm_source=partners&amp;utm_medium=autobizford&amp;utm_campaign=partners"
              target="_blank"
            >
              Agencia de Performance
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default SubFooter;
