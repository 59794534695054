import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "./index.css";

const Testimonios = () => {
  const testimonios = [
    {
      id: 1,
      texto:
        "El servicio fue excepcional. Me ayudaron en cada paso del proceso.",
      autor: "Ana Roleman",
      ubicacion: "Córdoba Cap.",
    },
    {
      id: 2,
      texto:
        "Increíble atención al cliente. Siempre dispuestos a resolver mis inquietudes.",
      autor: "Carlos Sánchez",
      ubicacion: "Bahía Blanca",
    },
    {
      id: 3,
      texto:
        "Me sorprendió lo claro y conciso que fueron en sus explicaciones. Muy recomendable.",
      autor: "Laura Palvo",
      ubicacion: "La Plata",
    },
    {
      id: 4,
      texto:
        "La calidad del servicio superó mis expectativas. Siempre amables y profesionales.",
      autor: "Diego López",
      ubicacion: "Mar del Plata",
    },
    {
      id: 5,
      texto:
        "Estoy muy satisfecho con la atención recibida. Cumplieron con todo lo prometido.",
      autor: "María Russo",
      ubicacion: "CABA",
    },
    {
      id: 6,
      texto:
        "No puedo estar más contento con mi experiencia. Lo recomendaría sin dudarlo.",
      autor: "Juan Gómez",
      ubicacion: "La Plata",
    },
  ];
  return (
    <div className="section noPadTop">
      <div className="container-lg flex flexCol">
        <h5 className="textCenter">Testimonios</h5>
        <h2 className="textCenter titleSec">
          Últimos comentarios de nuestros clientes
        </h2>
        <Swiper
          className="testimonioSwiper"
          modules={[Pagination, Autoplay]}
          pagination={{ clickable: true }}
          spaceBetween={40}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            650: {
              slidesPerView: 2,
            },
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        >
          {testimonios.map((testimonio) => (
            <SwiperSlide key={testimonio.id}>
              <h3>{testimonio.texto}</h3>
              <h4>
                {testimonio.autor} - {testimonio.ubicacion}
              </h4>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonios;
