const horarioOptions = [
  { value: "Por la mañana", text: "Por la mañana" },
  { value: "Por la tarde", text: "Por la tarde" },
  { value: "Por la noche", text: "Por la noche" },
  { value: "Cualquier horario", text: "Cualquier horario" },
];

const usadoOptions = [
  {
    value: "Si",
    defaultChecked: false,
  },
  {
    value: "No",
    defaultChecked: true,
  },
];

export { horarioOptions, usadoOptions };
