import React from "react";
import Hero from "../Hero";
import Modelos from "../Modelos";
import Testimonios from "../Testimonios";

const Home = () => {
  return (
    <>
      <Hero />
      <Modelos title="Cotiza tu Próximo Ford" />
      <Testimonios />
    </>
  );
};

export default Home;
