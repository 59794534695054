import React from "react";
import "./index.css";
import { useCons } from "../context/ConsContext";

const Hero = ({ img, dataButton }) => {
  const { buttonData } = useCons();

  const month = new Date(new Date()).toLocaleString("es", {
    month: "long",
  });

  return (
    <div className="fullH" style={img && { backgroundImage: `url(${img})` }}>
      <div className="container-lg">
        <div className="colorBox flex flexRow">
          <div className="textCol">
            <h4 className="relative">Solo por {month}</h4>
            <h1 className="relative">
              Nuevos Planes en Pesos.
              <br />
              <span>Tomamos tu Usado.</span>
            </h1>
            <h3 className="relative">
              Tené tu Ford 0KM pagando hasta el 100% en cuotas, sin sobreprecios
              y con un plan claro.
            </h3>
            <button
              className="btn whitebtn"
              onClick={() => buttonData(true, dataButton)}
            >
              Consultar ahora
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
