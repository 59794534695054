import { createContext, useContext, useState } from "react";

/* Imgs */
import maverick from "../assets/molelos/maverick.webp";
import transit from "../assets/molelos/transit.webp";
import rangera from "../assets/molelos/xl a.avif";
import rangers from "../assets/molelos/xls.avif";
import territory from "../assets/molelos/territory.webp";
import territoryImg from "../assets/molelos/territory/territory.avif";
import rangerImg from "../assets/hero.avif";

/* Galeria */
/* Ranger */
import ranger1 from "../assets/molelos/ranger/ranger1.jpeg";
import ranger2 from "../assets/molelos/ranger/ranger2.jpeg";
import ranger3 from "../assets/molelos/ranger/ranger3.jpeg";
import ranger4 from "../assets/molelos/ranger/ranger4.jpeg";
import ranger5 from "../assets/molelos/ranger/ranger5.jpeg";
import ranger6 from "../assets/molelos/ranger/ranger6.jpeg";
import ranger7 from "../assets/molelos/ranger/ranger7.jpeg";

/* Ranger */
import territory1 from "../assets/molelos/territory/territory1.jpeg";
import territory2 from "../assets/molelos/territory/territory2.jpeg";
import territory3 from "../assets/molelos/territory/territory3.jpeg";
import territory4 from "../assets/molelos/territory/territory4.jpeg";
import territory5 from "../assets/molelos/territory/territory5.jpeg";
import territory6 from "../assets/molelos/territory/territory6.jpeg";
import territory7 from "../assets/molelos/territory/territory7.jpeg";

/* Maverick */
import maverickImg from "../assets/molelos/maverick/maverickimg.avif";
import maverick1 from "../assets/molelos/maverick/maverick1.jpg";
import maverick2 from "../assets/molelos/maverick/maverick2.jpg";
import maverick3 from "../assets/molelos/maverick/maverick3.jpg";
import maverick4 from "../assets/molelos/maverick/maverick4.jpg";
import maverick5 from "../assets/molelos/maverick/maverick5.jpg";

/* Transit */
import transitImg from "../assets/molelos/transit/transitimg.avif";
import transit1 from "../assets/molelos/transit/transit1.jpg";
import transit2 from "../assets/molelos/transit/transit2.jpg";
import transit3 from "../assets/molelos/transit/transit3.jpg";
import transit4 from "../assets/molelos/transit/transit4.jpg";
import transit5 from "../assets/molelos/transit/transit5.jpg";

export const ConsContext = createContext();

export const useCons = () => {
  const context = useContext(ConsContext);
  if (!context) throw new Error("useCons must be used within a ConsContext");
  return context;
};

export const ConsContextProvider = ({ children }) => {
  const [popActive, setPopActive] = useState({
    active: false,
    clickModel: "",
  });

  const buttonData = (active, clickModel, modelName) => {
    setPopActive({
      active: active,
      clickModel: clickModel ? clickModel : "",
    });
    try {
      dataLayer.push({
        event: "CTA",
        model: modelName ? modelName : "Header",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const prices = {
    rangerxl: {
      /* Plan 80/20 */
      cuota: "315.000",
    },
    territory: {
      /* Plan 80/20 */
      cuota: "381.900",
    },
    maverick: {
      cuota: "367.200",
    },
    transit: {
      cuota: "545.000",
    },
  };

  const modelos = [
    {
      id: 2,
      link: "plan-rangerxl",
      nombre: "Ranger XL 4X2",
      modelo: "Ranger XL 4X2",
      cuota: prices.rangerxl.cuota,
      plan: "Plan 80/20",
      text1: `Cuota Bonificada`,
      text2: "Sin Sobreprecios",
      img: rangerImg,
      galeria: [ranger1, ranger2, ranger3, ranger4, ranger5, ranger6, ranger7],
      card: rangera,
      cupo: 4,
      dataButton: "Plan Ranger XL 4X2 80/20",
      disabled: false,
    },

    {
      id: 4,
      link: "plan-territory",
      nombre: "Territory SEL",
      modelo: "Territory SEL",
      cuota: prices.territory.cuota,
      plan: "Plan 80/20",
      text1: `Cuota Bonificada`,
      text2: "Sin Sobreprecios",
      img: territoryImg,
      galeria: [
        territory1,
        territory2,
        territory3,
        territory4,
        territory5,
        territory6,
        territory7,
      ],
      card: territory,
      cupo: 6,
      dataButton: "Plan Territory SEL",
      disabled: false,
    },
    {
      id: 5,
      link: "plan-maverick",
      nombre: "Maverick",
      modelo: "Maverick",
      cuota: prices.maverick.cuota,
      plan: "Plan 70/30",
      text1: `Cuota Bonificada`,
      text2: "Sin Sobreprecios",
      img: maverickImg,
      galeria: [maverick1, maverick2, maverick3, maverick4, maverick5],
      card: maverick,
      cupo: 6,
      dataButton: "Plan Maverick",
      disabled: false,
    },
    {
      id: 6,
      link: "plan-transit",
      nombre: "Transit",
      modelo: "Transit",
      cuota: prices.transit.cuota,
      plan: "Plan 70/30",
      text1: `Cuota Bonificada`,
      text2: "Sin Sobreprecios",
      img: transitImg,
      galeria: [transit1, transit2, transit3, transit4, transit5],
      card: transit,
      cupo: 6,
      dataButton: "Plan Transit",
      disabled: false,
    },
  ];

  const modelsOptions = modelos.map((model) => {
    return {
      value: model.dataButton,
      text: `${model.dataButton} (Cuota $${model.cuota})`,
      disabled: model.disabled,
    };
  });

  console.log(modelsOptions);

  return (
    <ConsContext.Provider
      value={{
        popActive,
        setPopActive,
        prices,
        buttonData,
        modelos,
        modelsOptions,
      }}
    >
      {children}
    </ConsContext.Provider>
  );
};
