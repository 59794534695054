import "./styles.css";
import "./base.css";
import { useEffect, useState } from "react";

/* PopUp import */
import PopUp from "./PopUp/index";
import Header from "./Header";
import Home from "./Home/Home";
import { ConsContextProvider } from "./context/ConsContext";
import SubFooter from "./SubFooter";

/* Router */
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import ModeloSimple from "./ModelSimple";
import Send from "./Send";

export default function App() {
  return (
    <div className="App">
      <ConsContextProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ScrollToTop />
          <Header />
          {/* Routes */}
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path=":model" element={<ModeloSimple />} />
            <Route path="/send" element={<Send />} />
            {/* Page Not Found */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          {/* End Routes */}
          <SubFooter />
          <PopUp />
        </BrowserRouter>
      </ConsContextProvider>
    </div>
  );
}
